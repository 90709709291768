<template>
    <div class="project-showcase">
      <div class="gradient-bg"></div>
      <div class="text-center mb-6">
      <span class="text-green-500 text-lg mb-1 block">04.</span>
      <h2 class="text-4xl font-bold text-white mb-2">My Projects</h2>
    </div>
      <!-- Mobile Navigation Arrows -->
      <div class="mobile-nav">
        <button class="mobile-nav-btn prev" @click="slidePrev">
          <ChevronLeftIcon class="nav-icon" />
        </button>
        <button class="mobile-nav-btn next" @click="slideNext">
          <ChevronRightIcon class="nav-icon" />
        </button>
      </div>
      
      <swiper
        :modules="[EffectCoverflow, Pagination, Navigation, Autoplay, EffectCreative]"
        :effect="'creative'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }"
        :creativeEffect="{
          prev: {
            translate: ['-120%', 0, -500],
            rotate: [0, 0, -15],
            opacity: 0
          },
          next: {
            translate: ['120%', 0, -500],
            rotate: [0, 0, 15],
            opacity: 0
          },
          current: {
            translate: [0, 0, 0],
            rotate: [0, 0, 0],
            opacity: 1
          }
        }"
        :pagination="{
          clickable: true,
          dynamicBullets: true
        }"
        :navigation="true"
        class="mySwiper"
        @slideChange="handleSlideChange"
        ref="swiperRef"
      >
        <swiper-slide v-for="project in projects" :key="project.id">
          <div class="project-card">
            <div class="card-content">
              <div class="project-image">
                <img :src="project.image" :alt="project.title" loading="lazy" />
                <div class="image-overlay"></div>
              </div>
              <div class="project-info">
                <h3 class="project-title">{{ project.title }}</h3>
                <p class="project-description">{{ project.description }}</p>
                <div class="project-tech">
                  <div v-for="tech in project.technologies" :key="tech" class="tech-item">
                    <img :src="tech" :alt="getTechName(tech)" class="tech-icon" />
                  </div>
                </div>
                <div class="project-buttons">
                  <a :href="project.previewLink" class="btn btn-preview" target="_blank" rel="noopener noreferrer" v-if="project.previewLink">
                    <span class="btn-content">
                      <EyeIcon class="btn-icon" />
                      Preview
                    </span>
                  </a>
                  <a v-if="project.codeLink" :href="project.codeLink" class="btn btn-code" target="_blank" rel="noopener noreferrer">
                    <span class="btn-content">
                      <CodeIcon class="btn-icon" />
                      Code
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { EffectCoverflow, Pagination, Navigation, Autoplay, EffectCreative } from 'swiper/modules';
  import { Eye as EyeIcon, Code as CodeIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'lucide-vue-next';
  
  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/effect-creative';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  
  const swiperRef = ref(null);
  
  const slidePrev = () => {
    if (swiperRef.value && swiperRef.value.swiper) {
      swiperRef.value.swiper.slidePrev();
    }
  };
  
  const slideNext = () => {
    if (swiperRef.value && swiperRef.value.swiper) {
      swiperRef.value.swiper.slideNext();
    }
  };
  
  const projects = ref([
    {
      id: 1,
      title: "Dream-Foot",
      description: "Dream Foot: A platform dedicated to the development of young football talent in Morocco. It showcases youth academies, training programs, and talent showcases.",
      image: require('@/assets/images/dream.png'),
      technologies: [
        require('@/assets/svg/vue.svg'),
        require('@/assets/svg/laravel.svg'),
        require('@/assets/svg/axios.svg'),
        require('@/assets/svg/tailwind.svg'),
        require('@/assets/svg/postman.svg'),
        require('@/assets/svg/mysql.svg'),
        require('@/assets/svg/trello.svg'),
      ],
      previewLink: "https://youtu.be/DRRkzg_NOCw?si=nKVqrC81uacJ0aHf",
    },
    {
      id: 2,
      title: "Note Blade",
      description: "Note Blade helps users efficiently organize and manage notes. It offers a user-friendly interface to create, categorize, and search notes seamlessly",
      image: require('@/assets/images/noteblade.png'),
      technologies: [
        require('@/assets/svg/laravel.svg'),
        require('@/assets/svg/mysql.svg'),
        require('@/assets/svg/tailwind.svg'),
        require('@/assets/svg/vite.svg'),
        require('@/assets/svg/composer.svg'),
        require('@/assets/svg/livewire.svg'),
        require('@/assets/svg/trello.svg'),

      ],
      codeLink: "https://github.com/Nabil-nl/NoteBlade.git"
    },
    {
      id: 3,
      title: "Github Insights",
      description: "GitHub Insights allows users to analyze repositories by providing metrics such as the most used programming languages and contributors' activity. It also helps search and explore user repositories .",
      image: require('@/assets/images/githubin.png'),
      technologies: [
        require('@/assets/svg/vue.svg'),
        require('@/assets/svg/css.svg'),
        require('@/assets/svg/api.svg'),
        require('@/assets/svg/trello.svg'),

      ],
      previewLink: "https://nabil-nl.github.io/github-insight/",
      codeLink: "https://github.com/Nabil-nl/github-insight.git"
    },
    {
      id: 4,
      title: "Elevator Simulator",
      description: "This elevator simulation allows users to select floors, animates the lift's movement between floors, and opens doors with smooth transitions. It also tracks and displays the current floor in real-time.",
      image: require('@/assets/images/elevator.png'),
      technologies: [
        require('@/assets/svg/js.svg'),
        require('@/assets/svg/html.svg'),
        require('@/assets/svg/css.svg'),
        require('@/assets/svg/trello.svg'),

      ],
      previewLink: "https://nabil-nl.github.io/Elevator-Simulator/",
      codeLink: "https://github.com/Nabil-nl/Elevator-Simulator.git"
    },
    
    {
      id: 5,
      title: "Todo List",
      description: "A To-Do List helps users manage tasks by adding, displaying, and keeping track of their completion status",
      image: require('@/assets/images/todolist.png'),
      technologies: [
        require('@/assets/svg/c.svg'),
      ],
      codeLink: "https://github.com/Nabil-nl/Todo-list-c.git"
    }
  ]);
  
  const handleSlideChange = () => {
    // Add any slide change animations here
  };
  
  const getTechName = (techPath) => {
    return techPath.split('/').pop().replace('.svg', 'dev').toUpperCase();
  };
  </script>
  
  <style scoped>
  .project-showcase {
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 10px 0;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #f8fafc;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
    z-index: 10;
  }
  
  .gradient-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(29, 78, 216, 0.15), transparent 60%);
    pointer-events: none;
  }
  
  .swiper {
    width: 100%;
    padding: 80px 0;
  }
  
  .swiper-slide {
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .project-card {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    background: rgba(30, 41, 59, 0.7);
    backdrop-filter: blur(10px);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
    transform-style: preserve-3d;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .card-content {
    height: 100%;
    display: flex;
  }
  
  .project-image {
    width: 50%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(30, 41, 59, 0.9));
  }
  
  .project-info {
    width: 50%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .project-title {
    font-size: 2rem;
    font-weight: 700;
    color: #f8fafc;
    margin: 0;
  }
  
  .project-description {
    font-size: 1.1rem;
    color: #cbd5e1;
    line-height: 1.6;
    margin: 0;
  }
  
  .project-tech {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  .tech-item {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: rgba(5, 0, 18, 0.393);
    padding: 6px;
    transition: transform 0.3s ease;
  }
  
  .tech-item:hover {
    transform: translateY(-4px);
  }
  
  .tech-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .tech-tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-8px);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.75rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  
  .tech-item:hover .tech-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-4px);
  }
  
  .project-buttons {
    margin-top: auto;
    display: flex;
    gap: 16px;
  }
  
  .btn {
    flex: 1;
    padding: 12px;
    border-radius: 12px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    transition: all 0.3s ease;
    border: 1px solid transparent;
  }
  
  .btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .btn-icon {
    width: 16px;
    height: 16px;
  }
  
  .btn-preview {
    background: rgba(59, 130, 246, 0.1);
    color: #60a5fa;
    border-color: rgba(59, 130, 246, 0.2);
  }
  
  .btn-preview:hover {
    background: rgba(59, 130, 246, 0.2);
    transform: translateY(-2px);
  }
  
  .btn-code {
    background: rgba(16, 185, 129, 0.1);
    color: #34d399;
    border-color: rgba(16, 185, 129, 0.2);
  }
  
  .btn-code:hover {
    background: rgba(16, 185, 129, 0.2);
    transform: translateY(-2px);
  }
  
  :deep(.swiper-pagination-bullet) {
    width: 10px;
    height: 10px;
    background: rgba(226, 232, 240, 0.3);
    transition: all 0.3s ease;
  }
  
  :deep(.swiper-pagination-bullet-active) {
    background: #3b82f6;
    transform: scale(1.2);
  }
  
  :deep(.swiper-button-next),
  :deep(.swiper-button-prev) {
    color: #3b82f6;
    width: 50px;
    height: 50px;
    background: rgba(30, 41, 59, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    transition: all 0.3s ease;
    top: 50%;
    transform: translateY(-50%);
  }
  
  :deep(.swiper-button-prev) {
    left: 5%;
  }
  
  :deep(.swiper-button-next) {
    right: 5%;
  }
  
  :deep(.swiper-button-next:hover),
  :deep(.swiper-button-prev:hover) {
    background: rgba(30, 41, 59, 0.9);
    transform: translateY(-50%) scale(1.1);
  }
  
  :deep(.swiper-button-next::after),
  :deep(.swiper-button-prev::after) {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .mobile-nav {
    display: none;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }
  
  .mobile-nav-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(30, 41, 59, 0.7);
    backdrop-filter: blur(10px);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: auto;
    transition: all 0.3s ease;
  }
  
  .mobile-nav-btn:hover {
    background: rgba(30, 41, 59, 0.9);
    transform: scale(1.1);
  }
  
  .mobile-nav-btn.prev {
    left: 10px;
  }
  
  .mobile-nav-btn.next {
    right: 10px;
  }
  
  .nav-icon {
    width: 24px;
    height: 24px;
    color: #3b82f6;
  }
  
  @media (max-width: 1024px) {
    .swiper-slide {
      width: 90vw;
      max-width: 700px;
      height: 400px;
    }
  
    .project-title {
      font-size: 1.75rem;
    }
  
    .project-description {
      font-size: 1rem;
    }
  
    .tech-item {
      width: 28px;
      height: 28px;
    }
  }
  
  @media (max-width: 820px) {
    .swiper-slide {
      width: 90vw;
      max-width: 600px;
      height: 450px;
    }
  
    .project-image,
    .project-info {
      width: 50%;
    }
  
    .project-info {
      padding: 24px;
    }
  
    .project-title {
      font-size: 1.5rem;
    }
  
    .project-description {
      font-size: 0.9rem;
    }
  
    .tech-item {
      width: 24px;
      height: 24px;
    }
  
    .btn {
      padding: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .project-showcase {
      padding: 20px 0;
    }
  
    .section-title {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .swiper {
      padding: 10px 0;
    }
  
    .swiper-slide {
      width: 85vw;
      max-width: 400px;
      height: 550px;
    }
  
    .card-content {
      flex-direction: column;
    }
  
    .project-image,
    .project-info {
      width: 100%;
    }
  
    .project-image {
      height: 40%;
    }
  
    .project-info {
      height: 60%;
      padding: 20px;
    }
  
    .project-title {
      font-size: 1.25rem;
      text-align: center;
    }
  
    .project-description {
      font-size: 0.875rem;
      text-align: center;
    }
  
    .project-tech {
      justify-content: center;
    }
  
    .tech-item {
      width: 24px;
      height: 24px;
    }
  
    .project-buttons {
      flex-direction: column;
      align-items: center;
    }
  
    .btn {
      width: 100%;
      max-width: 200px;
      padding: 10px;
    }
  
    .image-overlay {
      background: linear-gradient(to bottom, transparent, rgba(30, 41, 59, 0.9));
    }
  
    :deep(.swiper-button-next),
    :deep(.swiper-button-prev) {
      width: 36px;
      height: 36px;
      display: none;
    }
  
    :deep(.swiper-pagination) {
      bottom: 0px;
    }
  
    .mobile-nav {
      display: block;
    }
  }
  
  @media (max-width: 480px) {
    .section-title {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
  
    .swiper-slide {
      width: 92vw;
      height: 500px;
    }
  
    .project-card {
      border-radius: 16px;
    }
  
    .project-image {
      height: 35%;
    }
  
    .project-info {
      height: 65%;
      padding: 16px;
      gap: 10px;
    }
  
    .project-title {
      font-size: 1.1rem;
    }
  
    .project-description {
      font-size: 0.8rem;
      line-height: 1.5;
    }
  
    .tech-item {
      width: 20px;
      height: 20px;
      padding: 4px;
    }
  
    .project-buttons {
      margin-top: 10px;
    }
  
    .btn {
      padding: 8px;
      min-width: 100px;
      font-size: 0.9rem;
    }
  
    .btn-icon {
      width: 14px;
      height: 14px;
    }
  
    :deep(.swiper-pagination-bullet) {
      width: 8px;
      height: 8px;
    }
  }
  
  /* Add iPhone notch support */
  @supports (padding: max(0px)) {
    .project-showcase {
      padding-top: max(20px, env(safe-area-inset-top));
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  </style>