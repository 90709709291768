<template>
  <div 
    class="gradient-wrapper" 
    ref="container"
    @mousemove="handleMouseMove"
    :style="{ 
      '--gradient-primary': gradientColors.primary,
      '--gradient-secondary': gradientColors.secondary,
      '--gradient-background': gradientColors.background,
      minHeight: fullHeight ? '100vh' : 'auto'
    }"
  >
    <div 
      class="gradient-overlay"
      :style="{
        background: `radial-gradient(
          circle at ${mousePosition.x}px ${mousePosition.y}px,
          var(--gradient-primary),
          var(--gradient-secondary),
          var(--gradient-background)
        )`
      }"
    ></div>
    <div class="content" :class="{ 'content-centered': center }">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'

const props = defineProps({
  gradientColors: {
    type: Object,
    default: () => ({
      primary: '#f5f5f5',
      secondary: '#050810',
      background: '#050810'
    })
  },
  fullHeight: {
    type: Boolean,
    default: false
  },
  center: {
    type: Boolean,
    default: false
  }
})

const container = ref(null)
const mousePosition = reactive({ x: 0, y: 0 })

const handleMouseMove = (event) => {
  const rect = container.value.getBoundingClientRect()
  mousePosition.x = event.clientX - rect.left
  mousePosition.y = event.clientY - rect.top
}
</script>

<style scoped>
.gradient-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: var(--gradient-background);
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.8;
  mix-blend-mode: soft-light;
}

.content {
  position: relative;
  z-index: 10;
}

.content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: inherit;
}
</style>