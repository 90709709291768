<template>
    <div id="app">   
      <router-view></router-view>
             
    </div>
</template>
<script>
import '@/assets/css/global.css'
export default {
  name: 'App',
  components: {
 
  }
}

</script>