<template>
  <nav class="w-full md:w-[70rem] h-[60px] mx-auto flex items-center justify-center absolute inset-x-0 top-0 z-50 px-4 md:px-0">
    <div class="flex w-full items-center justify-center mx-auto p-4">
      <!-- Mobile menu button -->
      <button 
        @click="toggleMobileMenu"
        class="md:hidden text-white hover:text-green-400 fixed right-4 top-4 z-50"
      >
        <MenuIcon v-if="!isMobileMenuOpen" class="h-6 w-6" />
        <XIcon v-else class="h-6 w-6" />
      </button>

      <!-- Navigation Items -->
      <div 
        :class="[
          'flex flex-col md:flex-row items-center justify-center w-full h-screen md:h-auto',
          'fixed md:static inset-0 bg-[#0a192f] md:bg-transparent', 
          'transition-opacity duration-300 ease-in-out pt-16 md:pt-0',
          isMobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible md:opacity-100 md:visible'
        ]"
      >
        <ul class="flex flex-col md:flex-row items-center gap-8 md:gap-8 font-mono">
          <li v-for="(item, index) in navItems" :key="item.label">
            <a 
              :href="item.href"
              class="group flex items-center text-sm text-gray-400 hover:text-green-400 transition-colors"
              @click.prevent="scrollToSection(item.href)"
            >
              <span class="text-green-400 mr-2 text-xs">{{ String(index + 1).padStart(2, '0') }}.</span>
              {{ item.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { MenuIcon, XIcon } from 'lucide-vue-next'

const isMobileMenuOpen = ref(false)

const navItems = [
  { label: 'About', href: '#about' },
  { label: 'Skills', href: '#skills' },
  { label: 'Education', href: '#education' },
  { label: 'Projects', href: '#projects' },
  { label: 'Contact', href: '#contact' }
]

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value
  document.body.style.overflow = isMobileMenuOpen.value ? 'hidden' : ''
}

const closeMobileMenu = () => {
  if (window.innerWidth < 768) {
    isMobileMenuOpen.value = false
    document.body.style.overflow = ''
  }
}

const scrollToSection = (href) => {
  const targetId = href.substring(1)
  const targetElement = document.getElementById(targetId)
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' })
  }
  closeMobileMenu()
}

const handleResize = () => {
  if (window.innerWidth >= 768) {
    isMobileMenuOpen.value = false
    document.body.style.overflow = ''
  }
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>