<template>
  <footer class="py-8 px-4">
    <div class="max-w-lg mx-auto">
      <!-- Animated line -->
      <div class="relative h-px w-full bg-gray-700 overflow-hidden mb-6">
        
      </div>

      <!-- Navigation -->
      <nav class="mb-6">
        <ul class="flex flex-wrap justify-center gap-x-6 gap-y-2">
          <li v-for="(item, index) in navItems" :key="index">
            <a :href="item.href" 
               class="text-sm text-gray-400 hover:text-white transition-colors duration-300 relative group">
              {{ item.text }}
              <span class="absolute -bottom-1 left-0 w-0 h-px bg-green-400 transition-all duration-300 group-hover:w-full"></span>
            </a>
          </li>
        </ul>
      </nav>

      <!-- Copyright -->
      <p class="text-center text-gray-500 text-xs">
        &copy; {{ new Date().getFullYear() }} Nabil OUHMIDA. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const navItems = [
  { text: 'About', href: '#about' },
  { text: 'Skills', href: '#skills' },
  { text: 'Projects', href: '#projects' },
  { text: 'Contact', href: '#contact' }
]

const linePosition = ref(-100)
let animationFrame

const animateLine = () => {
  linePosition.value += 0.5
  if (linePosition.value > 100) {
    linePosition.value = -100
  }
  animationFrame = requestAnimationFrame(animateLine)
}

onMounted(() => {
  animationFrame = requestAnimationFrame(animateLine)
})

onUnmounted(() => {
  if (animationFrame) {
    cancelAnimationFrame(animationFrame)
  }
})
</script>