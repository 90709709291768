<template>
    <div class="py-16 px-4">
        <div class="max-w-3xl mx-auto">
            <!-- Education Section -->
            <div class="text-center mb-10">
                <span class="text-green-500 text-lg mb-1 block">03.</span>
                <h2 class="text-4xl font-bold text-white mb-4">My Educations</h2>
            </div>

            <!-- Education Cards -->
            <div class="space-y-4 mb-16">
                <div v-for="(edu, index) in education" :key="index"
                    class="bg-[#0d1117] rounded-lg overflow-hidden transition-all duration-300 hover:bg-gray-800/50">
                    <div class="flex items-center p-5">
                        <div class="w-16 h-16 flex-shrink-0 bg-gray-900/50 rounded-lg overflow-hidden">
                            <img :src="edu.image" :alt="edu.name" class="w-full h-full object-cover">
                        </div>
                        <div class="ml-6">
                            <h3 class="text-white font-semibold text-lg">{{ edu.name }}</h3>
                            <p class="text-gray-300 text-sm">{{ edu.position }}</p>
                            <p class="text-[#4ADE80] text-xs font-medium mt-1">{{ edu.date }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Certificates Section -->
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div v-for="(cert, index) in certificates" :key="index"
                    class="bg-[#0d1117] rounded-lg p-4 transition-all duration-300 hover:bg-gray-800/50">
                    <div class="flex items-center justify-between mb-3">
                        <div class="flex items-center">
                            <img :src="cert.logo" :alt="cert.platform" class="w-8 h-8 rounded">
                            <span class="text-white text-sm font-medium ml-2">{{ cert.platform }}</span>
                        </div>
                        <button @click="openCertificate(cert)"
                            class="text-[#4ADE80] hover:text-white transition-colors duration-300">
                            <EyeIcon class="w-5 h-5" />
                        </button>
                    </div>
                    <h4 class="text-white text-sm font-medium mb-2">{{ cert.title }}</h4>
                    <p class="text-gray-400 text-xs">{{ cert.date }}</p>
                </div>
            </div>

            <!-- Certificate Modal -->
            <div v-if="selectedCert" class="fixed inset-0 bg-black/80 flex items-center justify-center z-50 p-4"
                @click="selectedCert = null">
                <div class="max-w-4xl w-full bg-[#0d1117] rounded-lg overflow-hidden" @click.stop>
                    <div class="flex justify-between items-center p-4 border-b border-gray-800">
                        <h3 class="text-white font-medium">{{ selectedCert.title }}</h3>
                        <button @click="selectedCert = null" class="text-gray-400 hover:text-white">
                            <XIcon class="w-5 h-5" />
                        </button>
                    </div>
                    <div class="p-4">
                        <img :src="selectedCert.image" :alt="selectedCert.title" class="w-full rounded-lg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { EyeIcon, XIcon } from 'lucide-vue-next';

const education = ref([
    {
        name: "Simplon Maghreb - TALENT 4 STARTUPS",
        position: "Web Developer (Full Stack) - Laravel & Vue.js",
        date: "2024 (March - September)",
        image: require('@/assets/images/simplone.png')
    },
    {
        name: "OFPPT",
        position: "Diplôme Développement Digital (Full Stack)",
        date: "2021 - 2023",
        image: require('@/assets/images/ofppt.png')
    },

]);

const certificates = ref([
    {
        platform: "Coursera",
        title: "Agile Project Management",
        date: "January 2024",
        logo: require('@/assets/images/google.jpg'),
        image: require('@/assets/images/agile.png')
    },
    {
        platform: "Coursera",
        title: "Programming with JavaScript",
        date: "January 2024",
        logo: require('@/assets/images/meta.jpg'),
        image: require('@/assets/images/meta.png')
    },
    {
        platform: "Redis University",
        title: "Redis for JavaScript Developers",
        date: "October  2023",
        logo: require('@/assets/images/redise.png'),
        image: require('@/assets/images/myredise.png')
    }
]);

const selectedCert = ref(null);

const openCertificate = (cert) => {
    selectedCert.value = cert;
};
</script>

<style scoped>
.grid>div {
    animation: fadeIn 0.4s ease-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
