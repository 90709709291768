<template>
    <div class="min-h-screen w-full flex items-center justify-center p-8 ">
        <div class="w-full max-w-2xl space-y-8">
            <!-- Section Title -->
            <div class="text-center space-y-2">
                <span class="text-green-500 text-lg block">05.</span>
                <h2 class="text-4xl font-bold text-white">Contact Me</h2>
            </div>

            <!-- Contact Form -->
            <form @submit.prevent="handleSubmit" class="space-y-6">
                <div class="space-y-4">
                    <div class="relative">
                        <input type="text" v-model="form.name" placeholder="Your Name"
                            class="w-full bg-[#131B2E] text-gray-300 rounded-2xl pl-12 pr-6 py-4 outline-none border border-transparent focus:border-[#22c55e] transition-colors duration-300 placeholder-gray-500"
                            required />
                        <User class="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    </div>

                    <div class="relative">
                        <input type="email" v-model="form.email" placeholder="Your E-Mail"
                            class="w-full bg-[#131B2E] text-gray-300 rounded-2xl pl-12 pr-6 py-4 outline-none border border-transparent focus:border-[#22c55e] transition-colors duration-300 placeholder-gray-500"
                            required />
                        <Mail class="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    </div>

                    <div class="relative">
                        <input type="text" v-model="form.subject" placeholder="Your Subject"
                            class="w-full bg-[#131B2E] text-gray-300 rounded-2xl pl-12 pr-6 py-4 outline-none border border-transparent focus:border-[#22c55e] transition-colors duration-300 placeholder-gray-500"
                            required />
                        <MessageSquare
                            class="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    </div>

                    <div class="relative">
                        <textarea v-model="form.message" placeholder="Your Message" rows="6"
                            class="w-full bg-[#131B2E] text-gray-300 rounded-2xl pl-12 pr-6 py-4 outline-none border border-transparent focus:border-[#22c55e] transition-colors duration-300 placeholder-gray-500 resize-none"
                            required></textarea>
                        <SquarePen class="absolute left-4 top-6 text-gray-500 w-5 h-5" />
                    </div>
                </div>

                <button type="submit"
                    class="w-full bg-[#22c55e] hover:bg-[#21c55e]/90 text-white rounded-2xl px-6 py-4 font-medium transition-colors duration-300 flex items-center justify-center"
                    :disabled="isSubmitting">
                    <Send class="mr-2 w-5 h-5" />
                    {{ isSubmitting ? 'Sending...' : 'Send Message' }}
                </button>
            </form>

            <!-- Feedback Message -->
            <div v-if="feedbackMessage" :class="['text-center py-2 px-4 rounded-lg',
                feedbackMessage.type === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white']">
                {{ feedbackMessage.text }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { User, Mail, MessageSquare, SquarePen, Send } from 'lucide-vue-next'
import emailjs from '@emailjs/browser'

const form = reactive({
    name: '',
    email: '',
    subject: '',
    message: ''
})

const isSubmitting = ref(false)
const feedbackMessage = ref(null)

const handleSubmit = () => {
    isSubmitting.value = true
    feedbackMessage.value = null

    emailjs.send(
        'service_yj84zu8', // Replace with your EmailJS service ID
        'template_bcvxmnp', // Replace with your EmailJS template ID
        {
            from_name: form.name,
            from_email: form.email,
            subject: form.subject,
            message: form.message,
        },
        'xjYd6rOfYKyYHyhCt' // Replace with your EmailJS user ID
    )
        .then((result) => {
            console.log('Email sent successfully:', result.text);
            feedbackMessage.value = { type: 'success', text: 'Message sent successfully!' };
            // Reset form
            form.name = ''
            form.email = ''
            form.subject = ''
            form.message = ''
        }, (error) => {
            console.log('Failed to send email:', error.text);
            feedbackMessage.value = { type: 'error', text: 'Failed to send message. Please try again.' };
        })
        .finally(() => {
            isSubmitting.value = false
        });
}
</script>

<style scoped>
/* Custom scrollbar styling for the textarea */
textarea::-webkit-scrollbar {
    width: 8px;
}

textarea::-webkit-scrollbar-track {
    background: #131B2E;
    border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb {
    background: #AFFC41;
    border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb:hover {
    background: #AFFC41;
}
</style>