<template>
  <div class="fixed left-8 bottom-0 hidden lg:flex flex-col items-center gap-6">
    <!-- Social Links -->
    <a v-for="social in socials" :key="social.name" :href="social.url" target="_blank" rel="noopener noreferrer"
      class="text-gray-400 hover:text-green-400 transform hover:-translate-y-1 transition-all duration-300">
      <component :is="social.icon" class="w-5 h-5" />
      <span class="sr-only">{{ social.name }}</span>
    </a>

    <!-- Vertical Line -->
    <div class="w-px h-24 bg-gray-400/20 mt-4"></div>
  </div>
</template>

<script setup>
import { GithubIcon, InstagramIcon, Youtube, LinkedinIcon, } from 'lucide-vue-next'

const socials = [
  {
    name: 'GitHub',
    url: 'https://github.com/Nabil-nl',
    icon: GithubIcon
  },
  {
    name: 'LinkedIn',
    url: 'https://linkedin.com/in/nabil-ouhmida',
    icon: LinkedinIcon
  },
  {
    name: 'Youtube',
    url: 'https://youtube.com/@nbl.2.0?si=HnLtfbG9TciiBAxY',
    icon: Youtube
  },
  {
    name: 'Instagram',
    url: 'https://instagram.com/yourusername',
    icon: InstagramIcon
  },




]
</script>

<style scoped>
/* Add any additional custom styles here if needed */
</style>